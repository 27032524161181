import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileComponent} from './pages/profile/profile.component';
import {WalletsComponent} from './pages/profile/wallets/wallets.component';
import {NotificationsComponent} from './pages/profile/notifications/notifications.component';
import {WithdrawalWhitelistComponent} from './pages/profile/withdrawal-whitelist/withdrawal-whitelist.component';
import {IpWhitelistComponent} from './pages/profile/ip-whitelist/ip-whitelist.component';
import {TwoFactorAuthComponent} from './pages/profile/two-factor-auth/two-factor-auth.component';
import {PasswordComponent} from './pages/profile/password/password.component';
import {EnableAccountComponent} from './pages/profile/enable-account/enable-account.component';
import {MyActivityComponent} from './pages/profile/my-activity/my-activity.component';
import {MyProfileComponent} from './pages/profile/my-profile/my-profile.component';
import {IdentityVerificationComponent} from './pages/profile/identity-verification/identity-verification.component';
import {MarketsComponent} from './pages/markets/markets.component';
import {SearchComponent} from './pages/search/search.component';
import {AuthPageComponent} from './pages/auth-page/auth-page.component';
import {AuthGuard} from './services/auth.guard';
import {DataService} from './services/data.service';
import {KycPageComponent} from './pages/kyc-page/kyc-page.component';
import {SetLandingLanguageComponent} from './pages/landing/set-landing-language/set-landing-language.component';
import {FeesAndLimitsComponent} from './pages/fees-and-limits/fees-and-limits.component';
import {PublicApiComponent} from './pages/public-api/public-api.component';
import {BcxgComponent} from './pages/profile/bcxg/bcxg.component';
import {AdvcashComponent} from './pages/profile/advcash/advcash.component';
import {BuyCryptoComponent} from './pages/buy-crypto/buy-crypto/buy-crypto.component';
import {WalletStatus} from './pages/wallet-status/wallet-status.component';
import {RedirectGuard} from './services/redirectGuard';
import {MainWrapperComponent} from './pages/main-wrapper/main-wrapper.component';
import {ChartComponent} from './pages/markets/chart/chart.component';
import {DepositComponent} from './pages/profile/deposit/deposit.component';
import {WithdrawComponent} from './pages/profile/withdraw/withdraw.component';
import {HistoryComponent} from './pages/profile/history/history.component';
import {DocumentFlowComponent} from './pages/profile/document-flow/document-flow.component';
import {LandingComponent} from './pages/landing/landing.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {FaqComponent} from './pages/faq/faq.component';
import {ReceiveAuthTokenComponent} from './pages/receive-auth-token/receive-auth-token.component';
import {environment} from '../environments/environment';
import { BuyTDBonusesComponent } from './pages/buy-td-bonuses/buy-td-bonuses.component';
import {AltynBankComponent} from './pages/altyn-bank/altyn-bank.component';
import {PaymentStatusComponent} from './dialogs/payment-status/payment-status.component';
// import {PreventNavigationGuard} from './services/prevent-navigation.guard';

const landingHosts = new Set(['test-landing.intebix.kz', 'intebix.kz']);
const ssoHosts = new Set(['test-sso.intebix.kz', 'sso.intebix.kz']);
const localPorts = new Set(['4200', '4201', '4202']);

const isLocalHost: boolean = localPorts.has(window.location.port);
const isLandingHost: boolean = landingHosts.has(window.location.hostname) || window.location.port === '4201';
const isSsoHost: boolean = ssoHosts.has(window.location.hostname) || window.location.port === '4202';

const ssoRedirectUrl = isLocalHost
  ? `http://${window.location.hostname}:4202/sso?origin=${JSON.stringify(window.location.origin)}`
  : `${environment.ssoPage}/sso?origin=${JSON.stringify(window.location.origin)}`;

const tradeHost = isLocalHost ? `http://${window.location.hostname}:4200/` : environment.tradePage;
const homePage = isLocalHost ? `http://${window.location.hostname}:4200/landing` : environment.homepage;

const mainRoutes: Routes = [
  {path: 'altyn-bank', component: AuthPageComponent},
  {path: 'receive-token/:token', component: ReceiveAuthTokenComponent},
  {
    path: 'sso',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: ssoRedirectUrl,
    },
  },
  {path: '', pathMatch: 'full', redirectTo: '/markets'},
  {
    path: 'home',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: homePage,
    }
  },
  {path: 'landing', component: LandingComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'fees-and-limits', component: FeesAndLimitsComponent},
  {path: 'wallet-status', component: WalletStatus},
  {path: 'public-api', component: PublicApiComponent},
  {path: 'sign-up/:lang', component: SetLandingLanguageComponent},
  {path: 'search/:lang', component: SetLandingLanguageComponent},
  {path: 'login/:lang', component: SetLandingLanguageComponent},
  {path: 'markets', component: SearchComponent, data: {isMarkets: true}},
  {path: 'restore-password/:token', component: AuthPageComponent},
  {path: 'change-password/:token', component: AuthPageComponent},
  {path: 'login-verify/:token', component: AuthPageComponent},
  { path: 'validate-email/:token', component: AuthPageComponent },
  {path: 'payment/success', component: AuthPageComponent},
  {path: 'payment/failure', component: AuthPageComponent},
  {path: 'payment/cancellation', component: AuthPageComponent},
  // {path: 'payment/notification', component: AuthPageComponent},
  {path: 'payment/receipt-unverified', component: AuthPageComponent},
  {path: 'user-kyc/approve/:token', component: KycPageComponent},
  {path: 'user-kyc/reject/:token', component: KycPageComponent},
  {path: 'user-kyc/block/:token', component: KycPageComponent},
  {path: 'trades/:pair', component: MarketsComponent},
  {path: 'buy-crypto', component: BuyCryptoComponent, canActivate: [AuthGuard]},
  {path: 'buy-td-bonuses', component: BuyTDBonusesComponent, canActivate: [AuthGuard]},
  {
    path: 'about',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.biteeu.com/about'
    }
  },
  {
    path: 'docs/ippolicy',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.biteeu.com/docs/BITEEU IP Address  and Cookie Policy SITE.pdf'
    }
  },
  {
    path: 'docs/privacyestonia',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.biteeu.com/docs/Biteeu privacy Estonia SITE.pdf'
    }
  },
  {
    path: 'docs/termsofuseestonia',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.biteeu.com/docs/Terms of Use Estonia SITE.pdf'
    }
  },
  {
    path: 'faq',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.biteeu.com/faq'
    }
  },
  {
    path: 'twitter',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://twitter.com/BiteeuExchange'
    }
  },
  {
    path: 'instagram',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://instagram.com/biteeu_exchange?utm_source=ig_profile_share&igshid=1jxb6yl09mmea'
    }
  },
  {
    path: 'linkedin',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.linkedin.com/company/biteeu/?viewAsMember=true'
    }
  },
  {
    path: 'telegram',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://t.me/biteeu_chat'
    }
  },
  // {path: 'search', component: SearchComponent},
  {
    path: 'user-settings', component: ProfileComponent, canActivate: [AuthGuard],
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'my-profile'},
      {path: 'bcxg', component: BcxgComponent},
      {path: 'my-activity', component: MyActivityComponent},
      {path: 'my-profile', component: MyProfileComponent},
      {path: 'identity-verification', component: IdentityVerificationComponent},
      {path: 'enable-account', component: EnableAccountComponent},
      {path: 'password-settings', component: PasswordComponent},
      {path: 'two-factor-auth-settings', component: TwoFactorAuthComponent},
      {path: 'ip-whitelist', component: IpWhitelistComponent}, //  CLOSED
      {path: 'withdraw-whitelist', component: WithdrawalWhitelistComponent}, //  CLOSED
      {path: 'notifications', component: NotificationsComponent},
      {path: 'advcash', component: AdvcashComponent},
      {path: 'document-flow', component: DocumentFlowComponent},
    ]
  },
  {
    path: 'user-settings', canActivate: [AuthGuard],
    children: [
      {path: 'wallets', component: WalletsComponent},
      {path: 'history', component: HistoryComponent},
      {path: 'deposit', component: DepositComponent},
      {path: 'deposit/:currency', component: DepositComponent},
      {path: 'withdraw', component: WithdrawComponent},
      {path: 'withdraw/:currency', component: WithdrawComponent},
    ]
  },
  {path: '**', redirectTo: '/markets'}
];

const routes: Routes = [
  {
    path: 'altyn-bank',
    component: AltynBankComponent,
  },
  {path: 'altyn-bank', component: PaymentStatusComponent, outlet: 'status'},
  {
    path: 'trading-frame',
    component: ChartComponent,
    data: {
      noControls: true,
    }
  },
  {
    path: '',
    component: MainWrapperComponent,
    children: mainRoutes,
  },
  {path: 'payment-status-ok', component: AuthPageComponent, outlet: 'popup'},
  {path: 'payment-status-error', component: AuthPageComponent, outlet: 'popup'},
  {path: 'login', component: AuthPageComponent, outlet: 'popup'},
  {path: 'sign-up', component: AuthPageComponent, outlet: 'popup'},
  {path: 'restore-password', component: AuthPageComponent, outlet: 'popup'},
  {path: 'login-verify', component: AuthPageComponent, outlet: 'popup'}
];

const routes_landing: Routes = [
  {
    path: 'markets',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: tradeHost,
    }
  },
  {
    path: 'sso',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: ssoRedirectUrl,
    },
  },
  {path: 'receive-token/:token', component: ReceiveAuthTokenComponent},
  {path: 'login', component: AuthPageComponent, outlet: 'popup'},
  {path: 'sign-up', component: AuthPageComponent, outlet: 'popup'},
  {path: 'restore-password', component: AuthPageComponent, outlet: 'popup'},
  {path: 'login-verify', component: AuthPageComponent, outlet: 'popup'},
  {
    path: 'buy-crypto',
    canActivate: [RedirectGuard],
    component: BuyCryptoComponent,
    data: {
      externalUrl: tradeHost,
    }
  },
  {
    path: 'buy-td-bonuses',
    canActivate: [RedirectGuard],
    component: BuyTDBonusesComponent,
    data: {
      externalUrl: tradeHost,
    }
  },
  {
    path: 'user-settings', canActivate: [AuthGuard],
    children: [
      {
        path: 'wallets',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: `${tradeHost}user-settings/wallets`
        }
      },
      {
        path: 'my-profile',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: `${tradeHost}user-settings/my-profile`
        }
      },
      {
        path: 'advcash',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: `${tradeHost}user-settings/advcash`
        }
      },
      {
        path: 'bcxg',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: `${tradeHost}user-settings/bcxg`
        }
      },
      {
        path: 'identity-verification',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: `${tradeHost}user-settings/identity-verification`
        }
      },
      {
        path: 'my-activity',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: `${tradeHost}user-settings/my-activity`
        }
      },
      {
        path: 'password-settings',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: `${tradeHost}user-settings/password-settings`
        }
      },
      {
        path: 'two-factor-auth-settings',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: `${tradeHost}user-settings/two-factor-auth-settings`
        }
      }
    ]
  },
  {
    path: '',
    component: MainWrapperComponent,
    children: [
      {path: '', component: LandingComponent},
      {path: 'about-us', component: AboutUsComponent},
      {path: 'faq', component: FaqComponent},
      {path: '**', redirectTo: '/'}
    ],
  },

];

const sso_route: Routes = [
  {path: '**', component: AuthPageComponent}
];

const resolveRoute = () => {
  if (isSsoHost) {
    return sso_route;
  } else {
    if (isLandingHost) {
      return routes_landing;
    } else {
      return routes;
    }
  }
};

@NgModule({
  imports: [RouterModule.forRoot(resolveRoute())],
  exports: [RouterModule],
  providers: [DataService]
})
export class AppRoutingModule {
}
