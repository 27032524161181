import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {ThemeService} from '../../services/theme.service';
import {Router} from '@angular/router';
import {EurasiaBankPayload} from '../../models/eurasia-bank-payload';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  partnersClass = 'partners-carousel';
  destroySubject$: Subject<void> = new Subject();
  constructor(public dataService: DataService, public themeService: ThemeService,  private _httpClient: HttpClient, private router: Router) {
  }

  ngOnInit(): void {
    // const payload: EurasiaBankPayload = {
    //   amount: '2222',
    //   description: 'test',
    //   iin: '3333333333333',
    //   from: 'null',
    //   to: 'null'
    // };
    //
    // this._httpClient.post('https://api.intebix.kz/api/v1/acquiring/altynbank/buy', payload, {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    //   responseType: 'text' as 'json'
    // }).subscribe(
    //   (response) => {
    //     console.log(response);
    //     this.router.navigate(['/altyn-bank'], { queryParams: { html: response } });
    //   },
    //   (error) => {
    //     console.error('Ошибка:', error);
    //   }
    // );
    this.dataService.getUserProfile().toPromise();
    window.localStorage.setItem('darkMode', 'false');
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }

}
