import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LogInComponent} from '../../dialogs/log-in/log-in.component';
import {SignUpComponent} from '../../dialogs/sign-up/sign-up.component';
import {ForgotPasswordComponent} from '../../dialogs/forgot-password/forgot-password.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NewPasswordComponent} from '../../dialogs/new-password/new-password.component';
import {AuthService} from '../../services/auth.service';
import {InfoComponent} from '../../dialogs/info/info.component';
import {DataService} from '../../services/data.service';
import {TokensInterface} from '../../models/tokens.model';
import {Subscription} from 'rxjs/internal/Subscription';
import {PaymentStatusComponent} from '../../dialogs/payment-status/payment-status.component';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {
  private querySubscription: Subscription;
  private queryParams: any;
  public isAuthorizationProcessing: boolean;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dataService: DataService,
  ) {
    const token = this.route.snapshot.paramMap.get('token');
    let url = this.router.url;

    if (url.includes('popup')) {
      url = '/' + this.router.url.slice(this.router.url.lastIndexOf(':') + 1, -1);
    } else {
      url = this.router.url.indexOf('/') === this.router.url.lastIndexOf('/') ?
        this.router.url
        :
        this.router.url.slice(0, this.router.url.lastIndexOf('/'));
    }

    this.querySubscription = route.queryParams.subscribe(
      (queryParams: any) => {
        this.queryParams = queryParams;
        const origin = this.queryParams['origin'];
        if (origin) {
          let tokenResponse = localStorage.getItem('jwtToken');
          const decoded = JSON.parse(origin);
          if (this.route.snapshot.queryParams.check) {
            window.location.href = decoded + '/receive-token/' + tokenResponse;
          }
          if (JSON.parse(tokenResponse)?.accessToken) {
            this.dataService.getUserProfile().toPromise().then(
              () => window.location.href = decoded + '/receive-token/' + tokenResponse,
              () => {
                this.authService.logout();

                this.dialog.open(LogInComponent, {disableClose: false}).afterClosed(
                ).toPromise().then(
                  () => {
                    tokenResponse = localStorage.getItem('jwtToken');
                    window.location.href = decoded + '/receive-token/' + tokenResponse;
                  }
                );
              }
            );
          } /*else {
            setTimeout(() => {
              tokenResponse = localStorage.getItem('jwtToken');
              window.location.href = decoded + '/receive-token/' + tokenResponse;
            }, 2000);
          }*/
        }
      }
    );

    switch (url) {
      case '/sign-up': {
        this.dialog.open(SignUpComponent);
        break;
      }
      case '/login': {
        this.dialog.open(LogInComponent, {disableClose: false});
        break;
      }
      case '/payment-status-ok': {
        this.dialog.open(PaymentStatusComponent, {disableClose: true}).afterClosed().subscribe(() => {
          this.router.navigate(['markets']);
        });
        break;
      }
      case '/payment-status-error': {
        this.dialog.open(PaymentStatusComponent, {disableClose: true}).afterClosed().subscribe(() => {
          this.router.navigate(['buy-crypto']);
        });
        break;
      }
      case '/restore-password': {
        if (token) {
          this.dialog.open(NewPasswordComponent, {
            data: token
          });
        } else {
          this.dialog.open(ForgotPasswordComponent);
        }
        break;
      }
      case '/change-password': {
        this.authService.changePasswordWithToken(token)
          .subscribe((res: TokensInterface) => {
            if (res.accessToken) {
              this.authService.storeToken(res);
              this.dataService.getCurrenciesListFromServer();
            }
            this.dialog.open(InfoComponent, {
              data: {
                reason: 'password-change-success', message: ''
              }
            });
          }, err => {
            const message = (err.error.errorCode + ': ' + err.error.message)
              || err.message || 'There is an error with current password change';
            this.handleError('password-change-error', message);
          });
        break;
      }
      case '/login-verify': {
        if (token) {
          this.authService.loginWithEmail(token)
            .subscribe((res: TokensInterface) => {
              if (res.accessToken) {
                this.authService.storeToken(res);
                this.dataService.getCurrenciesListFromServer();
                this.router.navigate(['search']).then();
              }
            }, error1 => {
              const message = (error1.error.errorCode + ': ' + error1.error.message)
                || error1.message || 'There is an error with current login verification';
              this.handleError('login-verify', message);
            });
        } else {
          this.dialog.open(InfoComponent, {
            data: {reason: 'login-verify', message: ''}
          });
        }
        break;
      }
      case '/validate-email': {
        this.isAuthorizationProcessing = true;
        window.addEventListener('beforeunload', (e) => {
          e.preventDefault();
        });
        // this.handleAuthorizationProcess('authorization-processing', 'message');
        if (token) {
          this.authService.validateEmail(token)
            .subscribe((res: TokensInterface) => {
              if (res.accessToken) {
                this.authService.storeToken(res);
                this.router.navigate(['/user-settings/my-profile']).then();
              }
            }, error1 => {
              const message = (error1.error.errorCode + ': ' + error1.error.message)
                || error1.message || 'There is an error with current email verification';
              // this.handleError('validate-email-error', message);
              console.log('validate-email-error', message);
              this.router.navigate(['/user-settings/my-profile']).then();
            });
        }
        break;
      }
      case '/user-kyc/approve': {
        if (token) {
          this.authService.changeUserKycStatus('approve', token)
            .subscribe((res: TokensInterface) => {
              if (res.accessToken) {
                this.authService.storeToken(res);
                this.router.navigate(['/markets']).then();
              }
            });
        }
        break;
      }
      case '/user-kyc/reject': {
        if (token) {
          this.authService.changeUserKycStatus('reject', token)
            .subscribe((res: TokensInterface) => {
              if (res.accessToken) {
                this.authService.storeToken(res);
                this.router.navigate(['/markets']).then();
              }
            });
        }
        break;
      }
      case '/user-kyc/block': {
        if (token) {
          this.authService.changeUserKycStatus('block', token)
            .subscribe((res: TokensInterface) => {
              if (res.accessToken) {
                this.authService.storeToken(res);
                this.router.navigate(['/markets']).then();
              }
            });
        }
        break;
      }
      case '/payment': {
        switch (this.router.url.split('?')[0] || this.router.url) {
          case '/payment/success': {
            this.dialog.open(InfoComponent, {
              data: {
                reason: 'payment-success', message: ''
              }
            });
            break;
          }
          case '/payment/failure': {
            this.dialog.open(InfoComponent, {
              data: {
                reason: 'payment-failure', message: ''
              }
            });
            break;
          }
          case '/payment/cancellation': {
            this.dialog.open(InfoComponent, {
              data: {
                reason: 'payment-cancellation', message: ''
              }
            });
            break;
          }
          // case '/payment/notification': {
          //   this.dialog.open(InfoComponent, {data: {
          //       reason: 'payment-notification', message: ''
          //     }});
          //   break;
          // }
          case '/payment/receipt-unverified': {
            this.dialog.open(InfoComponent, {
              data: {
                reason: 'payment-receipt-unverified', message: ''
              }
            });
            break;
          }
        }
        break;
      }
      default: {
        this.dialog.open(LogInComponent, {disableClose: false});
        break;
      }
    }
  }

  private handleAuthorizationProcess(reason: string, message?: string): void {
    this.dialog.open(InfoComponent, {
      data: {reason: reason, message: message || ''}
    });

  }

  private handleError(reason: string, message?: string): void {
    this.dialog.open(InfoComponent, {
      data: {reason: reason, message: message || ''}
    });
  }
}
