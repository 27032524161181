import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {SvgService} from '../../services/svg.service';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import { en } from './footer-links-data';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FOOTER_LINKS } from './footer-links-data/footer-links';
import {ProfileState} from '../../store/profile.state';
import {fromMobx} from '../../store/rx-from-mobx';
import {HostListener} from '@angular/core';
import {MobileApplicationState} from '../../store/mobile-application.state';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy, AfterViewInit {
  public isLightTheme = false;
  public userIsCokiesAgreed: number;
  public isClosedCookieBar: boolean;
  destroySubject$: Subject<void> = new Subject();
  public usefulLinks = FOOTER_LINKS;
  public socialLinks = en.footer.socials;
  public supportLinks = en.footer.support;
  public currentLang: string;
  public isMarketListPage$ = this.dataService.isMarketListPageSubject.asObservable();
  private screenHeight: number;
  private screenWidth: number;
  isMobileApplication: boolean;
  constructor(public svgService: SvgService,
              public dataService: DataService,
              public profileState: ProfileState,
              private translateService: TranslateService,
              public mobileApplicationState: MobileApplicationState,
              ) {
    this.getUserIsCokiesAgreedData();
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });

    this.getScreenSize();
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = this.translateService.currentLang;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isMobileApplication = this.mobileApplicationState.getMobileApplicationState();
    }, 500);
  }

  public getUserIsCokiesAgreedData() {
    fromMobx(() => this.userIsCokiesAgreed)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(response => {
        this.userIsCokiesAgreed = response;
        if (this.userIsCokiesAgreed === 0) {
          this.isClosedCookieBar = false;
        }
      });
  }

  public getCurrentYear(): number {
    return new Date().getFullYear();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  displayChildren($event) {
    if (this.screenWidth <= 860) {
      const target = $event.currentTarget,
        childrenLi = target.querySelectorAll('li.toggleDropDown'),
        arrow = target.querySelector('img.arrow');

      childrenLi.forEach(li => {
        if (li.style.display === 'none') {
          li.style['display'] = 'block';
          arrow.src = '../../assets/icons/arrow-down.png';
        } else {
          li.style['display'] = 'none';
          arrow.src = '../../assets/icons/arrow-up.png';
        }
      });
    }
  }
}
